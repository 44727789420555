@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-secondary text-primary;
  }
  input::placeholder {
    @apply text-primary;
  }
  .h2 {
    @apply font-primary text-[45px] mb-4;
  }
  .h3 {
    @apply text-2xl font-primary font-bold tracking-[1px] mb-2;
  }
  .btn {
    @apply text-[15px] tracking-[3px] font-tertiary uppercase text-white flex-1 transition-all px-8 flex justify-center items-center;
  }
  .btn-sm {
    @apply h-[48px];
  }
  .btn-lg {
    @apply h-[60px];
  }
  .btn-primary {
    @apply bg-accent hover:bg-accent-hover;
  }
  .btn-secondary {
    @apply bg-[#17bf9e] hover:bg-accent;
  }
  .btn-white {
    @apply bg-white text-[#0a0a0a];
  }
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 999;
}

p {
  color: #596b65;
  font-size: 1rem;
  line-height: 35px;
  font-weight: 400;
}